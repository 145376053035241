import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Subject, BehaviorSubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthLogin } from "../Auth/auth-model";
import { NotificationService } from "../shared/notification.service";
import { LogService } from "../shared/log.service";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";
import { HelperService } from "../shared/helper.service";
import { ProfileService } from "../shared/profile.service";
import { ErrorService } from "../shared/error/error.service";
import * as i0 from "@angular/core";
import * as i1 from "../shared/error/error.service";
import * as i2 from "../shared/helper.service";
import * as i3 from "../shared/notification.service";
import * as i4 from "../shared/log.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common/http";
import * as i7 from "../shared/profile.service";
var AuthService = /** @class */ (function () {
    function AuthService(
    // private dialog: DialogService,
    errorService, helpService, notification, logger, router, http, profileService) {
        this.errorService = errorService;
        this.helpService = helpService;
        this.notification = notification;
        this.logger = logger;
        this.router = router;
        this.http = http;
        this.profileService = profileService;
        this.apiUrl = environment.apiUrl;
        this.isLoadingListener = new Subject();
        this.authStatusListener = new Subject();
        this.accessTokenSubject = new BehaviorSubject(null);
    }
    AuthService.prototype.register = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/bank/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createBank = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/bank/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createBankStaff = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/bankstaff/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createSidMgt = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/sid_mgt/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createSidStaff = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/sid_staff/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createMgtAdmin = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/mgt_admin/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.createDelivery = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.bank_code,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/delivery_agent/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.login(loginInfo);
            // this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.completeReg = function (registerInfo) {
        var _this = this;
        var updateInfo = {
            sex: registerInfo.value.sex,
            phone: registerInfo.value.phone,
            address: registerInfo.value.address,
            card_id: registerInfo.value.card_id,
            // referral_email: '',
            referral_email: registerInfo.value.referral_email,
            // birth_date: registerInfo.value.birth_date.toLocaleDateString(),
            birth_date: null,
            profile_status: true,
        };
        this.logger.log(updateInfo);
        return this.http
            .put(this.apiUrl + "loyalty/user/update/" + this.id + "/", updateInfo)
            .subscribe(function (res) {
            _this.logger.log(res);
            _this.notification.success(":: Succesfully Created account");
            _this.isUploading = false;
            registerInfo.reset();
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    AuthService.prototype.checkCard = function (cardID) {
        this.isLoadingListener.next(true);
        this.cardInvalid = false;
        return this.http.post(this.apiUrl + "teller/check/card/", {
            card_id: cardID.toUpperCase(),
        });
    };
    AuthService.prototype.isEmailTaken = function (email) {
        console.log(email);
        return this.http.post(this.apiUrl + "auth/check-unique/", { field_type: 'email', field_value: email }).pipe(
        // delay(1000),
        map(function (res) { return res; }), map(function (res) { return res.status; })).subscribe(function (res) { return console.log(res); });
    };
    AuthService.prototype.isUsernameTaken = function (username) {
        return this.http.post(this.apiUrl + "auth/check-unique/", { field_type: 'username', field_value: username }).pipe(
        // delay(1000),
        map(function (res) { return res; }), map(function (res) { return res.status; }));
    };
    AuthService.prototype.getToken = function () {
        return this.accessToken;
    };
    AuthService.prototype.getIsAuth = function () {
        return this.isAuthenticated;
    };
    AuthService.prototype.getUserId = function () {
        return this.currentUserId;
    };
    AuthService.prototype.getAuthStatusListener = function () {
        return this.authStatusListener.asObservable();
    };
    AuthService.prototype.getIsLoadingListener = function () {
        return this.isLoadingListener.asObservable();
    };
    AuthService.prototype.autoAuthUser = function () {
        var authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        var now = new Date();
        var expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        if (expiresIn > 0) {
            this.accessToken = authInformation.token;
            this.isAuthenticated = true;
            this.currentUserId = Number(authInformation.userId);
            this.timedLogOut(expiresIn / 1000);
            this.authStatusListener.next(true);
        }
    };
    AuthService.prototype.timedLogOut = function (duration) {
        var _this = this;
        this.tokenTimer = setTimeout(function () {
            _this.logout();
        }, duration * 1000);
    };
    AuthService.prototype.saveAuthData = function (token, refreshToken, expirationDate, userId, userGroup, firstname, lastname, username, phone, assigned_courier, bank_code, shipper_id, loginResponse) {
        localStorage.setItem("login_response", JSON.stringify(loginResponse));
        localStorage.setItem("tokenAccess", token);
        localStorage.setItem("token", token);
        localStorage.setItem("tokenRefresh", refreshToken);
        localStorage.setItem("expiration", expirationDate.toISOString());
        localStorage.setItem("user_id", JSON.stringify(userId));
        localStorage.setItem("user_group", JSON.stringify(userGroup));
        localStorage.setItem("firstname", JSON.stringify(firstname));
        localStorage.setItem("lastname", JSON.stringify(lastname));
        localStorage.setItem("username", JSON.stringify(username));
        localStorage.setItem("phone", JSON.stringify(phone));
        if (assigned_courier) {
            localStorage.setItem("assigned_courier", JSON.stringify(assigned_courier));
        }
        if (bank_code) {
            localStorage.setItem("bank_code", JSON.stringify(bank_code));
        }
        if (shipper_id) {
            localStorage.setItem("shipper_id", JSON.stringify(shipper_id));
        }
    };
    AuthService.prototype.clearAuthData = function () {
        // localStorage.removeItem("isAuth");
        // localStorage.removeItem("tokenRefresh");
        // localStorage.removeItem("tokenAccess");
        // localStorage.removeItem("expiration");
        // localStorage.removeItem("userId");
        // localStorage.removeItem("userGroup");
        localStorage.clear();
    };
    AuthService.prototype.getAuthData = function () {
        var token = localStorage.getItem("tokenAccess");
        var refresh = localStorage.getItem("tokenRefresh");
        var expirationDate = localStorage.getItem("expiration");
        var userId = localStorage.getItem("user_id");
        var userGroup = localStorage.getItem("user_group");
        if (!token || !expirationDate) {
            return;
        }
        return {
            token: token,
            expirationDate: new Date(expirationDate),
            userId: userId,
            userGroup: userGroup,
        };
    };
    AuthService.prototype.getUserID = function () {
        var token = localStorage.getItem("tokenAccess");
        var expirationDate = localStorage.getItem("expiration");
        var userId = localStorage.getItem("user_id");
        if (!token || !expirationDate) {
            return;
        }
        return userId;
    };
    AuthService.prototype.getUserGroupExt = function () {
        var token = localStorage.getItem("tokenAccess");
        var expirationDate = localStorage.getItem("expiration");
        var userGroup = JSON.parse(localStorage.getItem("user_group"));
        if (!token || !expirationDate) {
            return;
        }
        return userGroup;
    };
    AuthService.prototype.getIsAdmin = function () {
        return this.isAdmin;
    };
    AuthService.prototype.getIsRootAdmin = function () {
        return this.isRootAdmin;
    };
    AuthService.prototype.getJWT = function (loginInfo) {
        return this.http.post(this.apiUrl + "api/token/", loginInfo);
    };
    AuthService.prototype.onRefreshToken = function () {
        var _this = this;
        var refresh = this.refreshToken || localStorage.getItem("tokenRefresh");
        return this.http
            .post(this.apiUrl + "api/refresh/token/", { refresh: refresh })
            .subscribe(function (data) {
            // get new Token and set new token
            // this.logger.log(data);
            // // console.log(data);
            _this.accessToken = data.access;
            var expiresInDuration = 1200;
            var now = new Date();
            var expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
            localStorage.setItem("tokenAccess", _this.accessToken);
            localStorage.setItem("expiration", expirationDate.toISOString());
            // this.router.navigate(["/dashboard"]);
        });
    };
    AuthService.prototype.onRefreshToken2 = function () {
        var refresh = this.refreshToken || localStorage.getItem("tokenRefresh");
        return this.http.post(this.apiUrl + "api/refresh/token/", { refresh: refresh });
        // .subscribe((data) => {
        //   // get new Token and set new token
        //   // this.logger.log(data);
        //   // // console.log(data);
        //   this.accessToken = data.access;
        //   const expiresInDuration = 1200;
        //   const now = new Date();
        //   const expirationDate = new Date(
        //     now.getTime() + expiresInDuration * 1000
        //   );
        //   localStorage.setItem("tokenAccess", this.accessToken);
        //   localStorage.setItem("expiration", expirationDate.toISOString());
        //   // this.router.navigate(["/dashboard"]);
        // });
    };
    AuthService.prototype.getUserGroup = function (res) {
        if (res.groups[0] === 1) {
            this.isRootAdmin = true;
            this.isAdmin = false;
        }
        else if (res.groups[0] === 2) {
            this.isAdmin = true;
            this.isRootAdmin = false;
        }
        else {
            // something something
        }
    };
    AuthService.prototype.setExpirationDate = function () {
        var expiresInDuration = 1200;
        var now = new Date();
        var expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
        return expirationDate;
    };
    AuthService.prototype.login = function (form) {
        var _this = this;
        this.http
            .post(this.apiUrl + "auth/login/", form)
            .subscribe(function (response) {
            _this.getUserGroup(response);
            _this.refreshToken = response.refresh;
            _this.accessToken = response.access;
            _this.logger.log(response);
            if (_this.accessToken) {
                // this.timedLogOut(expiresInDuration);
                _this.currentUserId = Number(response.id);
                // this.profileService.getProfileById(this.currentUserId);
                _this.isAuthenticated = true;
                _this.authStatusListener.next(true);
                var expirationDate = _this.setExpirationDate();
                _this.saveAuthData(_this.accessToken, _this.refreshToken, expirationDate, _this.currentUserId, response.groups[0], response.firstname, response.lastname, response.username, response.phone, response.assigned_courier, response.bank_code, response.shipper_id, response);
                _this.router.navigate(["/dashboard"]);
                _this.isLoadingListener.next(false);
                // isTokenExpired
                // let jdh = new JwtHelperService();
                // console.log(jdh.isTokenExpired(this.accessToken));
                // console.log(jdh.decodeToken(this.accessToken));
            }
            // this.ngxService.startBackgroundLoader('loader-01');
        }, function (error) {
            // console.log(error)
            // console.log(error.error)
            // this.isLoading = false;
            _this.isLoadingListener.next(false);
            if (error.status == 401 && error.error.message) {
                _this.errorMessage = error.error.message;
                _this.errorService.throwError(_this.errorMessage);
                _this.logger.log(_this.errorMessage);
            }
            else {
                _this.errorMessage = error.error.message || "An unknown error occured";
                _this.errorService.throwError(_this.errorMessage);
                _this.logger.log(_this.errorMessage);
            }
        });
    };
    AuthService.prototype.changePassword = function (body /*PasswordChange*/) {
        var _this = this;
        // console.log(body)
        this.http.put(this.apiUrl + "auth/change/p/", body).subscribe(function (data) {
            // console.log(data)
            _this.router.navigate(["/login"]);
        }, function (err) {
            // console.log(err)
            // console.log(err.error)
        });
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        this.accessToken = null;
        this.isAuthenticated = false;
        this.authStatusListener.next(false);
        this.currentUserId = null;
        clearTimeout(this.tokenTimer);
        this.clearAuthData();
        localStorage.clear();
        this.router.navigate([""]);
        return this.http.get(this.apiUrl + "auth/logout/").subscribe(function (res) {
            _this.logger.log(res);
        }, function (err) {
            _this.logger.log(err);
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ErrorService), i0.inject(i2.HelperService), i0.inject(i3.NotificationService), i0.inject(i4.LogService), i0.inject(i5.Router), i0.inject(i6.HttpClient), i0.inject(i7.ProfileService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
