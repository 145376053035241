<h2 mat-dialog-title>AUTHORISATION FOR PROXY COLLECTION </h2>
<mat-dialog-content class="mat-typography">
  <p>This form is to be filled and completed by Account holder/Card holder should they be unable to collect the item and
    would like a proxy to collect/receive on their behalf. If patient is below 18 years old, the form should be signed
    by proxy’s parent or legal guardian.</p>

  <p>You hereby authorise
    <strong>
      {{ data.proxy_first_name }}
    </strong>
    <strong>
      {{ data.proxy_last_name }}
    </strong>
    as your proxy to collect your item on your behalf.
  </p>

  <p>You hereby understand and acknowledge that your proxy must produce the selected means of identification for
    verification upon delivery, before the item can be released: </p>

  <ol>
    <li>
      National Identity Card
    </li>
    <li>
      Driver’s license
    </li>
    <li>
      etc
    </li>
  </ol>

  <ul>
    <li>
      You hereby declare and confirm that the information you have given above is accurate and true to the best of your
      knowledge, and that the requisite information is required for the sole purpose stated above.
    </li>
    <li>
      You confirm that the proxy’s personal data is given in compliance with the Nigeria Data Protection laws and the
      appropriate legal basis.
    </li>
    <li>
      You understand that you may be liable for prosecution for making a false declaration.
    </li>
    <li>
      Further, you confirm that you shall not hold SID Digital or any of its employees, servants or agents responsible
      in any way whatsoever for the release of the said item to any party by you or your proxy in the event of any loss
      or damage arising directly or indirectly, as a result or in connection with its delivery. By reason of the
      aforesaid, you undertake full responsibility and liability arising from the release of the item.
    </li>
  </ul>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Agree</button>
</mat-dialog-actions>
