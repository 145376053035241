<div class="header  pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="row my-6">
          <div class="col-12">
            <!-- <button [routerLink]="['/departments']" routerLinkActive="router-link-active"
              class="btn btn-white text-primary">
              <i class="fa fa-chevron-left"></i>
              <span class="inner-text">
                Back to Departments
              </span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="card-wrapper">

        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h3 class="mb-0">{{title | titlecase}}</h3>
          </div>
          <!-- Card body -->
          <div class="card-body">

            <form [formGroup]="form" (submit)="onSave()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">OTP</label>
                    <input formControlName="otp" type="text" class="form-control" placeholder="Enter OTP here">

                    <p *ngIf="otp.invalid && (otp.touched || otp.dirty )" class="text-danger">
                      OTP is a required field
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Tracking Number</label>
                    <input formControlName="package_tracking_number" type="text" class="form-control"
                      placeholder="Enter tracking number here">

                    <p *ngIf="package_tracking_number.invalid && (package_tracking_number.touched || package_tracking_number.dirty )"
                      class="text-danger">
                      tracking number is a required field
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">First Name</label>
                    <input formControlName="proxy_first_name" type="text" class="form-control"
                      placeholder="Enter first name here">

                    <p *ngIf="proxy_first_name.invalid && (proxy_first_name.touched || proxy_first_name.dirty)"
                      class="text-danger">
                      first name is a required field
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Last Name</label>
                    <input formControlName="proxy_last_name" type="text" class="form-control"
                      placeholder="Enter last name here">

                    <p *ngIf="proxy_last_name.invalid && (proxy_last_name.touched || proxy_last_name.dirty )"
                      class="text-danger">
                      last name is a required field
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Phone Number</label>
                    <input formControlName="proxy_phone_number" type="text" class="form-control"
                      placeholder="EG: 08089457871">

                    <p *ngIf="proxy_phone_number.invalid && (proxy_phone_number.touched || proxy_phone_number.dirty)"
                      class="text-danger">
                      phone number is a required field
                    </p>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" [routerLink]="['/track-landing']" routerLinkActive="router-link-active"
                type="button">Back to track package</button>
              <button [disabled]="isLoading" class="btn btn-primary" type="submit">
                <i *ngIf="isLoading" class="fa fa-spin fa-spinner mr-2"></i> Add Proxy</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
