<div class="main-content">
  <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
    <div class="container px-4">
      <a class="navbar-brand" routerLinkActive="active" [routerLink]="['/']">
        <!-- <img alt="logo" src="../../../assets/img/INSIGHT SID 512.webp" /> -->
        <img class="logo" alt="logo" src="../../../assets/img/UBA-Logo.webp" />
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
        aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLinkActive="active" [routerLink]="['/']">
                <!-- <img alt="logo" src="../../../assets/img/INSIGHT SID 512.webp" /> -->
                <img class="logo" alt="logo" src="../../../assets/img/UBA-Logo.webp" />
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item mb-2">
            <div class="dropdown">
              <a class="nav-link nav-link-icon btn btn-primary dropdown-toggle" id="dropdownMenuButton" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,-20">
                <i class="fa fa-user-plus"></i>
                <span class="nav-link-inner--text">Register</span>
              </a>
              <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/bank-register']">
                  <span class="">as Bank</span>
                </a>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/bank-staff-register']">
                  <span class="">as Bank Staff</span>
                </a>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/sid-mgt-register']">
                  <span class="">as SecureID Management</span>
                </a>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/sid-staff-register']">
                  <span class="">as SecureID Staff</span>
                </a>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/mgt-admin-register']">
                  <span class="">as Management Admin</span>
                </a>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/delivery-register']">
                  <span class="">as Delivery Agent</span>
                </a>
              </div>
            </div>
          </li> -->
          <li class="nav-item">
            <a class="btn btn-primary" routerLinkActive="active" [routerLink]="['/login']">
              <i class="fa fa-key mr-2"></i>
              <span class="nav-link-inner-text">Login</span>
            </a>
          </li>
          <li *ngIf="pwa.promptEvent" class="nav-item text-white">
            <a class="btn btn-primary mr-2 text-white" (click)="installPwa()">
              <i class="fa fa-download"></i>
              <span class="text-white">Download</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
<footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a rel="noopener" href="https://www.secureidltd.com" class="font-weight-bold ml-1" target="_blank">
            <img class="sid-footer-logo" src="assets\img\siddiglog.png" alt="siddiglog">
            <!-- SID Digital -->
          </a>
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <img class="footer-logo mr-2" alt="logo" src="../../../assets/img/INSIGHT SID 512.webp" />
            Insight {{ version }}
            <!-- <a href="https://www.secureidltd.com?ref=ada-footer-auth-layout" class="nav-link" target="_blank">Creative
              Tim</a> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
