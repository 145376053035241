import { MatConfirmDialogComponent } from "./mat-confirm-dialog/mat-confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.openConfirmDialog = function (msg) {
        return this.dialog.open(MatConfirmDialogComponent, {
            width: "450px",
            panelClass: "confirm-dialog-container",
            disableClose: true,
            // position: { top: "10px" },
            data: {
                message: msg,
            },
        });
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
