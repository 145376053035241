import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/Auth/auth.service";
import { PwaService } from "src/app/pwa.service";
import { HelperService } from "src/app/shared/helper.service";
import { ProfileService } from "src/app/shared/profile.service";

declare interface RouteInfo {
  path?: string;
  title?: string;
  icon?: string;
  iconColor?: string;
  class?: string;
  type?: string;
  active?: boolean;
  children?: any[];
}

export const SIDAROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/company",
    title: "Manage Shipper",
    type: "link",
    icon: "fa-building",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/departments",
    title: "Departments",
    type: "link",
    icon: "fa-building",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/pos",
    title: "Dispatch Device",
    type: "link",
    icon: "fa-mobile",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/couriers",
    title: "Couriers",
    type: "link",
    icon: "fa-truck",
    iconColor: "text-primary",
    class: "",
  },
  {
    title: "Sorting List",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sort-list",
        title: "Sorting List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/track-package",
        title: "Track Package",
        icon: "fa-search",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sort-list/upload",
        title: "Upload Sort List",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Banks",
    icon: "fa-university",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/bank/banks",
        title: "Banks",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/bank-staff",
        title: "Bank Staff List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/super-code-list",
        title: "Super Codes",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/processor-list",
        title: "Processors",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/access-code-list",
        title: "Bank Access Codes",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Jobs",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/jobs",
        title: "Job List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/jobs/issue-comments",
        title: "Issue Comments",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/jobs/job-issues",
        title: "Job Issues",
        icon: "fa-exclamation-triangle ",
        iconColor: "text-primary",
        class: "",
        type: "link",
        active: false,
      },
    ],
  },
  {
    title: "Manage Users",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/users/staff-list",
        title: "Staff List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/mgt-admin-create",
        title: "Create Sid Mgt Admin",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/sid-mgt-create",
        title: "Create Sid Mgt Staff",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/bank-staff-create",
        title: "Create Bank Staff",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/bank-create",
        title: "Create Bank Admin",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/users/delivery-create",
      //   title: "Create Delivery Agent",
      //   icon: "fa-suitcase",
      //   class: "",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/users/sid-staff-create",
        title: "Create Sid Staff Admin",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
];

export const SIDMGTADMINROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/company",
    title: "Manage Shipper",
    type: "link",
    icon: "fa-building",
    iconColor: "text-primary",
    class: "",
  },
  {
    path: "/departments",
    title: "Departments",
    type: "link",
    icon: "fa-building",
    iconColor: "text-primary",
    class: "",
  },
  {
    title: "Couriers",
    icon: "fa-truck",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/couriers",
        title: "Couriers",
        type: "link",
        icon: "fa-truck",
        iconColor: "text-primary",
        class: "",
      },
      {
        path: "/pos",
        title: "Dispatch Device",
        type: "link",
        icon: "fa-mobile",
        iconColor: "text-primary",
        class: "",
      },
    ],
  },
  {
    title: "Sorting List",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sort-list",
        title: "Sorting List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/track-package",
        title: "Track Package",
        icon: "fa-search",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sort-list/upload",
        title: "Upload Sort List",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Banks",
    icon: "fa-university",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/bank/banks",
        title: "Banks",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/bank-staff",
        title: "Bank Staff List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/super-code-list",
        title: "Super Codes",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/processor-list",
        title: "Processors",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/bank/access-code-list",
        title: "Bank Access Codes",
        icon: "fa-upload ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Jobs",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/jobs",
        title: "Job List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/jobs/issue-comments",
        title: "Issue Comments",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/jobs/job-issues",
        title: "Job Issues",
        icon: "fa-exclamation-triangle ",
        iconColor: "text-primary",
        class: "",
        type: "link",
        active: false,
      },
    ],
  },
  {
    title: "Manage Users",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/users/staff-list",
        title: "Staff List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/users/mgt-admin-create",
      //   title: "Create Sid Mgt Admin",
      //   icon: "fa-suitcase",
      //   class: "",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/users/sid-mgt-create",
        title: "Create Sid Mgt Staff",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/bank-staff-create",
        title: "Create Bank Staff",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/bank-create",
        title: "Create Bank Admin",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/users/delivery-create",
      //   title: "Create Delivery Agent",
      //   icon: "fa-suitcase",
      //   class: "",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/users/sid-staff-create",
        title: "Create Sid Staff Admin",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
];

export const SIDMGTROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  {
    title: "Sorting List",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/sort-list",
        title: "Sorting List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/track-package",
        title: "Track Package",
        icon: "fa-search",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Jobs",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/jobs",
        title: "Job List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/jobs/issue-comments",
      //   title: "Issue Comments",
      //   icon: "fa-suitcase",
      //   class: "",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
      {
        path: "/jobs/job-issues",
        title: "Job Issues",
        icon: "fa-exclamation-triangle ",
        iconColor: "text-primary",
        class: "",
        type: "link",
        active: false,
      },
    ],
  },
];

export const DELIVERYADMINROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  {
    title: "Deliveries",
    icon: "fa-briefcase",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/couriers/delivery-agent-list",
        title: "Dispatch Agents",
        type: "link",
        icon: "fa-truck",
        iconColor: "text-primary",
        class: "",
      },
      {
        path: "/sort-list",
        title: "Sorting List",
        icon: "fa-suitcase",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      // {
      //   path: "/track-package",
      //   title: "Track Package",
      //   icon: "fa-search",
      //   class: "",
      //   type: "link",
      //   iconColor: "text-primary",
      //   active: false,
      // },
    ],
  },
  {
    path: "/users/delivery-create",
    title: "Create Delivery Agent",
    icon: "fa-suitcase",
    class: "",
    type: "link",
    iconColor: "text-primary",
    active: false,
  },
];

export const BankRoutes: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  {
    title: "Job",
    icon: "fa-shopping-basket",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/track-package",
        title: "Track Package",
        icon: "fa-search",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sort-list",
        title: "Delivery Status",
        icon: "fa-suitcase ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  {
    title: "Manage Staff",
    icon: "fa-users",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/bank/bank-staff",
        title: "Staff List",
        icon: "fa-server",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/users/bank-staff-create",
        title: "Create Staff",
        icon: "fa-user-plus",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  // {
  //   title: "Settings",
  //   icon: "fa-cogs",
  //   iconColor: "text-primary",
  //   class: "",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/settings/appearance-settings",
  //       title: "Account Settings",
  //       icon: "fa-address-book ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //     {
  //       path: "/settings/appearance-settings",
  //       title: "Appearance Settings",
  //       icon: "fa-paint-brush ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //     {
  //       path: "/giftcard-vendors",
  //       title: "Language",
  //       icon: "fa-language ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //   ],
  // },
];

export const BankStaffRoutes: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icon: "fa-home",
    iconColor: "text-primary",
    class: "",
  },
  // {
  //   title: "Jobs",
  //   icon: "fa-briefcase",
  //   iconColor: "text-primary",
  //   class: "",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     // {
  //     //   path: "/jobs",
  //     //   title: "Job List",
  //     //   icon: "fa-suitcase",
  //     //   class: "",
  //     //   type: "link",
  //     //   iconColor: "text-primary",
  //     //   active: false,
  //     // },
  //     // {
  //     //   path: "/jobs/job-search",
  //     //   title: "Job Search",
  //     //   icon: "fa-search ",
  //     //   class: "",
  //     //   type: "link",
  //     //   iconColor: "text-primary",
  //     //   active: false,
  //     // },
  //     // {
  //     //   path: "/jobs/job-issues",
  //     //   title: "Job Issues",
  //     //   icon: "fa-exclamation-triangle ",
  //     //   iconColor: "text-primary",
  //     //   class: "",
  //     //   type: "link",
  //     //   active: false,
  //     // },
  //   ],
  // },
  {
    title: "Job",
    icon: "fa-shopping-basket",
    iconColor: "text-primary",
    class: "",
    type: "sub",
    active: false,
    children: [
      {
        path: "/track-package",
        title: "Track Package",
        icon: "fa-search",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
      {
        path: "/sort-list",
        title: "Delivery Status",
        icon: "fa-suitcase ",
        class: "",
        type: "link",
        iconColor: "text-primary",
        active: false,
      },
    ],
  },
  // {
  //   title: "Settings",
  //   icon: "fa-cogs",
  //   iconColor: "text-primary",
  //   class: "",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/settings/appearance-settings",
  //       title: "Account Settings",
  //       icon: "fa-address-book ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //     {
  //       path: "/settings/appearance-settings",
  //       title: "Appearance Settings",
  //       icon: "fa-paint-brush ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //     {
  //       path: "/giftcard-vendors",
  //       title: "Language",
  //       icon: "fa-language ",
  //       class: "",
  //       type: "link",
  //       active: false,
  //     },
  //   ],
  // },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  // public customerMenu: any[];
  public isCollapsed = true;
  panelOpenState = false;

  isRootAdmin;
  isAdmin;
  profile: any;

  sidA: boolean = false;
  sidMgtAdmin: boolean = false;
  sidMgt: boolean = false;
  sidStaff: boolean = false;
  bankAdmin: boolean = false;
  bankStaff: boolean = false;
  deliveryAdmin: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    public pwa: PwaService,
    private helper: HelperService,
    private profileService: ProfileService
  ) { }

  getRoutes() {
    if (this.sidA) {
      this.menuItems = SIDAROUTES.filter((menuItem) => menuItem);
    } else if (this.bankAdmin) {
      this.menuItems = BankRoutes.filter((menuItem) => menuItem);
      const loginResponse = this.helper.getLoginResponse();
      if (loginResponse.allow_bank_upload) {
        // add upload sorting list if allow_bank_upload is true
        const sortingListRoute: RouteInfo = {
          path: "/sort-list/upload",
          title: "Upload Sort List",
          icon: "fa-upload ",
          class: "",
          type: "link",
          iconColor: "text-primary",
          active: false,
        };
        this.menuItems.push(sortingListRoute);
      }
    } else if (this.bankStaff) {
      this.menuItems = BankStaffRoutes.filter((menuItem) => menuItem);
    } else if (this.sidMgtAdmin) {
      this.menuItems = SIDMGTADMINROUTES.filter((menuItem) => menuItem);
    } else if (this.sidMgt) {
      this.menuItems = SIDMGTROUTES.filter((menuItem) => menuItem);
    } else if (this.deliveryAdmin) {
      this.menuItems = DELIVERYADMINROUTES.filter((menuItem) => menuItem);
    }
  }

  ngOnInit() {
    this.getUserGroup();
    const userId = this.authService.getUserId();
    this.profileService.getProfileById(userId);
    this.profileService.getProfileListener().subscribe((data) => {
      this.profile = data;
    });
    this.getRoutes();
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.helper
      .getToggleValue()
      .subscribe((value) => (this.isCollapsed = value));
  }

  getUserGroup() {
    const userGroup = this.helper.getUserGroup();

    switch (userGroup) {
      case 1:
        this.sidA = true;
        break;
      case 2:
        this.sidMgtAdmin = true;
        break;
      case 3:
        this.sidMgt = true;
        break;
      case 4:
        this.sidStaff = true;
        break;
      case 5:
        this.bankAdmin = true;
        break;
      case 6:
        this.bankStaff = true;
        break;
      case 8:
        this.deliveryAdmin = true;
        break;

      default:
        break;
    }
  }

  toggleNav(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  installPwa(): void {
    this.pwa.promptEvent.prompt();
  }

  onLogout() {
    this.authService.logout();
  }
}
