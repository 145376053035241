import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-proxy-consent-dialog",
  templateUrl: "./proxy-consent-dialog.component.html",
  styleUrls: ["./proxy-consent-dialog.component.scss"],
})
export class ProxyConsentDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() { }
}
