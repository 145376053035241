<div [ngClass]="{'bg-primary': adminTrack}" class="header  pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="row my-6">
          <div class="col-12">
            <!-- <button [routerLink]="['/departments']" routerLinkActive="router-link-active"
              class="btn btn-white text-primary">
              <i class="fa fa-chevron-left"></i>
              <span class="inner-text">
                Back to Departments
              </span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="card-wrapper">

        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h3 class="mb-0">{{title | titlecase}}</h3>
          </div>
          <!-- Card body -->
          <div class="card-body">

            <form [formGroup]="form" (submit)="onSave()">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-control-label">Track ID</label>
                    <input formControlName="track_id" type="text" class="form-control"
                      placeholder="Enter Track Id here">
                  </div>
                </div>
              </div>
              <button class="btn btn-primary mr-2" type="submit">Track</button>
              <button *ngIf="!adminTrack" class="btn btn-primary" [routerLink]="['/add-proxy']"
                routerLinkActive="router-link-active" type="button">Add Proxy</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
