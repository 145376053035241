import { OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { ProxyConsentDialogComponent } from "../proxy-consent-dialog/proxy-consent-dialog.component";
import { HelperService } from "../shared/helper.service";
import { NotificationService } from "../shared/notification.service";
import { UserService } from "../users/user.service";
var AddProxyComponent = /** @class */ (function () {
    function AddProxyComponent(fb, userService, router, notificationService, dialog, helperService) {
        this.fb = fb;
        this.userService = userService;
        this.router = router;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.helperService = helperService;
        this.title = "";
        this.form = new FormGroup({});
    }
    AddProxyComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            otp: ["", [Validators.required]],
            package_tracking_number: ["", [Validators.required]],
            proxy_first_name: ["", [Validators.required]],
            proxy_last_name: ["", [Validators.required]],
            proxy_phone_number: ["", [Validators.required]],
        });
    };
    Object.defineProperty(AddProxyComponent.prototype, "otp", {
        get: function () {
            return this.form.get("otp");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddProxyComponent.prototype, "package_tracking_number", {
        get: function () {
            return this.form.get("package_tracking_number");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddProxyComponent.prototype, "proxy_first_name", {
        get: function () {
            return this.form.get("proxy_first_name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddProxyComponent.prototype, "proxy_last_name", {
        get: function () {
            return this.form.get("proxy_last_name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddProxyComponent.prototype, "proxy_phone_number", {
        get: function () {
            return this.form.get("proxy_phone_number");
        },
        enumerable: true,
        configurable: true
    });
    AddProxyComponent.prototype.onSave = function () {
        var _this = this;
        if (this.form.invalid) {
            this.helperService.validateAllFormFields(this.form);
            return;
        }
        this.isLoading = true;
        this.dialog
            .open(ProxyConsentDialogComponent, {
            data: this.form.value,
        })
            .afterClosed()
            .subscribe(function (dialogResponse) {
            if (dialogResponse) {
                _this.userService.addProxy(_this.form.value).subscribe(function (response) {
                    _this.isLoading = false;
                    _this.notificationService.success(response.message);
                    _this.router.navigate(["/"]);
                }, function (err) {
                    _this.isLoading = false;
                    _this.notificationService.error(err.error.message);
                });
            }
            _this.isLoading = false;
        });
    };
    return AddProxyComponent;
}());
export { AddProxyComponent };
