import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { DialogService } from "../shared/dialog.service";
import { NotificationService } from "../shared/notification.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { LogService } from "../shared/log.service";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private company: any;
  private companyUpdate = new Subject<any>();
  private companies: any[] = [];
  private companiesUpdate = new Subject<any[]>();
  // private jobStatuses: { items: number; pages: number; result: any[] };
  // private jobStatuesUpdate = new Subject<{
  //   items: number;
  //   pages: number;
  //   result: any[];
  // }>();

  private apiUrl = environment.apiUrl;

  constructor(
    private dialog: DialogService,
    private notification: NotificationService,
    private http: HttpClient,
    private router: Router,
    // private ngxService: NgxUiLoaderService,
    private logger: LogService
  ) {}

  createCompany(form) {
    this.http.post(`${this.apiUrl}app/company_details/`, form.value).subscribe(
      (res) => {
        form.reset();
        this.notification.success("::Created Successfully");
        this.router.navigate(["/company"]);
      },
      (err: any) => {
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }

  getCompanyByID(id) {
    // get Earnings List
    this.http.get(`${this.apiUrl}app/company_details/${id}/`).subscribe(
      (res: any[]) => {
        this.logger.log(res);
        this.company = res;
        this.companyUpdate.next({ ...this.company });
      },
      (err: any) => {
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }

  getCompanyUpdate() {
    return this.companyUpdate.asObservable();
  }

  getCompanies(limit = 10, page = 1) {
    // const query = `?limit=${limit}&page=${page}`;
    this.http
      // .get<{ items: number; pages: number; result: any[] }>(
      //   `${this.apiUrl}app/company_details/`
      // )
      .get<any[]>(`${this.apiUrl}app/company_details/`)
      .subscribe(
        (res: any[]) => {
          this.companies = res;
          // this.jobStatuses.result = res.result.reverse();
          // this.jobStatuesUpdate.next({ ...this.jobStatuses });
          this.companiesUpdate.next([...this.companies]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  getCompaniesUpdate() {
    return this.companiesUpdate.asObservable();
  }

  updateCompany(form) {
    const company = form.value;
    //
    // const earning = form.value;
    this.http
      .patch(`${this.apiUrl}app/company_details/${company.id}/`, company)
      .subscribe(
        (response) => {
          const updatedEarnings = [...this.companies];
          const oldPostIndex = updatedEarnings.findIndex(
            (e) => e.id === company.id
          );
          updatedEarnings[oldPostIndex] = company;
          this.companies = updatedEarnings;
          this.companiesUpdate.next([...this.companies]);
          this.notification.success(":: Company Updated");
          // this.ngxService.stopBackground();
          // form.resetForm();
          this.router.navigate(["/company"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  deleteCompany(id) {
    this.logger.log(`item deleted`);
    this.http.delete(`${this.apiUrl}app/company_details/${id}/`).subscribe(
      (res) => {
        const updatedEarnings = this.companies.filter(
          (earning) => earning.id !== id
        );
        this.companies = updatedEarnings;
        this.companiesUpdate.next([...this.companies]);
        this.notification.success("Company Deleted");
      },
      (err: any) => {
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }
}
