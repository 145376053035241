var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { DialogService } from "../shared/dialog.service";
import { NotificationService } from "../shared/notification.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { LogService } from "../shared/log.service";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";
import * as i0 from "@angular/core";
import * as i1 from "../shared/dialog.service";
import * as i2 from "../shared/notification.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "../shared/log.service";
var CompanyService = /** @class */ (function () {
    function CompanyService(dialog, notification, http, router, 
    // private ngxService: NgxUiLoaderService,
    logger) {
        this.dialog = dialog;
        this.notification = notification;
        this.http = http;
        this.router = router;
        this.logger = logger;
        this.companyUpdate = new Subject();
        this.companies = [];
        this.companiesUpdate = new Subject();
        // private jobStatuses: { items: number; pages: number; result: any[] };
        // private jobStatuesUpdate = new Subject<{
        //   items: number;
        //   pages: number;
        //   result: any[];
        // }>();
        this.apiUrl = environment.apiUrl;
    }
    CompanyService.prototype.createCompany = function (form) {
        var _this = this;
        this.http.post(this.apiUrl + "app/company_details/", form.value).subscribe(function (res) {
            form.reset();
            _this.notification.success("::Created Successfully");
            _this.router.navigate(["/company"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    CompanyService.prototype.getCompanyByID = function (id) {
        var _this = this;
        // get Earnings List
        this.http.get(this.apiUrl + "app/company_details/" + id + "/").subscribe(function (res) {
            _this.logger.log(res);
            _this.company = res;
            _this.companyUpdate.next(__assign({}, _this.company));
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    CompanyService.prototype.getCompanyUpdate = function () {
        return this.companyUpdate.asObservable();
    };
    CompanyService.prototype.getCompanies = function (limit, page) {
        var _this = this;
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        // const query = `?limit=${limit}&page=${page}`;
        this.http
            // .get<{ items: number; pages: number; result: any[] }>(
            //   `${this.apiUrl}app/company_details/`
            // )
            .get(this.apiUrl + "app/company_details/")
            .subscribe(function (res) {
            _this.companies = res;
            // this.jobStatuses.result = res.result.reverse();
            // this.jobStatuesUpdate.next({ ...this.jobStatuses });
            _this.companiesUpdate.next(_this.companies.slice());
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    CompanyService.prototype.getCompaniesUpdate = function () {
        return this.companiesUpdate.asObservable();
    };
    CompanyService.prototype.updateCompany = function (form) {
        var _this = this;
        var company = form.value;
        //
        // const earning = form.value;
        this.http
            .patch(this.apiUrl + "app/company_details/" + company.id + "/", company)
            .subscribe(function (response) {
            var updatedEarnings = _this.companies.slice();
            var oldPostIndex = updatedEarnings.findIndex(function (e) { return e.id === company.id; });
            updatedEarnings[oldPostIndex] = company;
            _this.companies = updatedEarnings;
            _this.companiesUpdate.next(_this.companies.slice());
            _this.notification.success(":: Company Updated");
            // this.ngxService.stopBackground();
            // form.resetForm();
            _this.router.navigate(["/company"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    CompanyService.prototype.deleteCompany = function (id) {
        var _this = this;
        this.logger.log("item deleted");
        this.http.delete(this.apiUrl + "app/company_details/" + id + "/").subscribe(function (res) {
            var updatedEarnings = _this.companies.filter(function (earning) { return earning.id !== id; });
            _this.companies = updatedEarnings;
            _this.companiesUpdate.next(_this.companies.slice());
            _this.notification.success("Company Deleted");
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.DialogService), i0.inject(i2.NotificationService), i0.inject(i3.HttpClient), i0.inject(i4.Router), i0.inject(i5.LogService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
