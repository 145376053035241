import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { ProxyConsentDialogComponent } from "../proxy-consent-dialog/proxy-consent-dialog.component";
import { HelperService } from "../shared/helper.service";
import { NotificationService } from "../shared/notification.service";
import { UserService } from "../users/user.service";

@Component({
  selector: "app-add-proxy",
  templateUrl: "./add-proxy.component.html",
  styleUrls: ["./add-proxy.component.scss"],
})
export class AddProxyComponent implements OnInit {
  isLoading;
  title: string = "";
  form: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      otp: ["", [Validators.required]],
      package_tracking_number: ["", [Validators.required]],
      proxy_first_name: ["", [Validators.required]],
      proxy_last_name: ["", [Validators.required]],
      proxy_phone_number: ["", [Validators.required]],
    });
  }

  get otp() {
    return this.form.get("otp");
  }

  get package_tracking_number() {
    return this.form.get("package_tracking_number");
  }

  get proxy_first_name() {
    return this.form.get("proxy_first_name");
  }

  get proxy_last_name() {
    return this.form.get("proxy_last_name");
  }

  get proxy_phone_number() {
    return this.form.get("proxy_phone_number");
  }

  onSave() {
    if (this.form.invalid) {
      this.helperService.validateAllFormFields(this.form);
      return;
    }
    this.isLoading = true;
    this.dialog
      .open(ProxyConsentDialogComponent, {
        data: this.form.value,
      })
      .afterClosed()
      .subscribe((dialogResponse) => {
        if (dialogResponse) {
          this.userService.addProxy(this.form.value).subscribe(
            (response: any) => {
              this.isLoading = false;
              this.notificationService.success(response.message);
              this.router.navigate(["/"]);
            },
            (err) => {
              this.isLoading = false;
              this.notificationService.error(err.error.message);
            }
          );
        }
        this.isLoading = false;
      });
  }
}
