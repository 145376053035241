import { Injectable } from "@angular/core";
import { HelperService } from "../shared/helper.service";
import { NotificationService } from "../shared/notification.service";
import { LogService } from "../shared/log.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { switchMap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";
import { Subject } from "rxjs";
import { CompanyService } from "../company/company.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  isUploading: boolean;
  private isLoadingListener = new Subject<boolean>();
  private id: number;
  private apiUrl = environment.apiUrl;
  private staff: { items: number; pages: number; result: any[] };
  private staffUpdate = new Subject<{
    items: number;
    pages: number;
    result: any[];
  }>();

  constructor(
    private helpService: HelperService,
    private notification: NotificationService,
    private logger: LogService,
    private router: Router,
    private http: HttpClient,
    private companyService: CompanyService
  ) { }

  getIsLoadingListener() {
    return this.isLoadingListener.asObservable();
  }

  addProxy(payload) {
    return this.http.post(`${this.apiUrl}app/delivery-proxy/`, payload);
  }

  createBank(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    this.isLoadingListener.next(true);
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      bank_code: registerInfo.bank_code,
      assigned_courier: registerInfo.assigned_courier,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    const shipper_info = {
      value: {
        allow_job_upload: registerInfo.allow_job_upload,
        allow_delivery_via_proxy: registerInfo.allow_delivery_via_proxy,
        name: `${registerInfo.first_name} ${registerInfo.last_name}`,
        email: registerInfo.email,
        phone: registerInfo.phone,
        address: registerInfo.address,
        city: registerInfo.city,
        country_code: registerInfo.country_code,
        bank_code: registerInfo.bank_code,
      },
    };

    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/bank/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.companyService.createCompany(shipper_info);
          this.notification.success(
            `:: Succesfully Created account, Username: ${regInfo.username}, password: ${regInfo.password}`
          );
          // this.login(loginInfo);
          this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createBankStaff(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    this.isLoadingListener.next(true);
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      bank_staff_position: registerInfo.bank_staff_position,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/bankstaff/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createSidMgt(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    this.isLoadingListener.next(true);
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/sid_mgt/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createSidStaff(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    this.isLoadingListener.next(true);
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/sid_staff/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createMgtAdmin(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    this.isLoadingListener.next(true);
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/sida/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res: any) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  sidCreateDelivery(registerInfo: any) {
    this.isUploading = true;
    this.isLoadingListener.next(true);

    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
      courier: registerInfo.courier,
    };

    const updateInfo = {
      // sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      // account_number: registerInfo.account_number,
      // account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "DVY",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/delivery_agent/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/couriers/delivery-agent-list"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createDelivery(registerInfo: any) {
    this.isUploading = true;
    this.isLoadingListener.next(true);

    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      // sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      // bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.city,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      // account_number: registerInfo.account_number,
      // account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "Oth",
      is_staff: "Dvy",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/delivery_agent/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoadingListener.next(false);
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          // this.login(loginInfo);
          this.router.navigate(["/couriers/delivery-agent-list"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  isEmailTaken(email: string) {
    return this.http.get("assets/users.json").pipe(
      // delay(1000),
      // map(res => res.json()),
      map((users: any[]) => users.filter((user) => user.email === email)),
      map((users: any[]) => !users.length)
    );
  }

  isUsernameTaken(email: string) {
    return this.http.get("assets/users.json").pipe(
      // delay(1000),
      map((res) => res)
      // map((users: any[]) => users.filter(user => user.email === email)),
      // map((users: any[]) => !users.length)
    );
  }

  getStaffList(limit = 10, page = 1) {
    const query = `?limit=${limit}&page=${page}`;
    this.http
      .get<{ items: number; pages: number; result: any[] }>(
        `${this.apiUrl}app/all/sid_users/${query}`
      )
      .subscribe(
        (res) => {
          // console.log(res);
          res.result.length ? (this.staff = res) : (this.staff.result = []);
          this.staffUpdate.next({ ...this.staff });
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  getStaffUpdate() {
    return this.staffUpdate.asObservable();
  }

  toggleStaff(staff) {
    this.logger.log(staff);
    // // console.log(staff);
    this.http
      .post(`${this.apiUrl}app/user/activate_deactivate/`, staff)
      .subscribe(
        (data) => {
          staff.type
            ? this.notification.success("User Activated")
            : this.notification.warn("user Deactivated");
          const updatedStaffList = [...this.staff.result];
          const oldStaff = updatedStaffList.find(
            (s) => s.user_id === staff.user_id
          );
          oldStaff.status = staff.type;
          const oldStaffIndex = updatedStaffList.findIndex(
            (s) => s.user_id === staff.user_id
          );
          updatedStaffList[oldStaffIndex] = oldStaff;
          this.staffUpdate.next({ ...this.staff });
        },
        (err: any) => {
          this.logger.error(err);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }
}
